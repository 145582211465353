import styled, { css, keyframes } from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';

const AboutFirst = () => {
    const Div = styled.div`
    margin-top: 70px;
    `;
    return(
        <>
        <Div>about 첫번째 페이지</Div>
        </>

    )

}
export default AboutFirst;