import React from "react";

const Inquiry= ()=>{

    return(
        <>
        <div>Inquiry</div>
        </>

    )
}

export default Inquiry;